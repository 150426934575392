import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {pageGutters} from "../css/layout.module.css"
import * as styles from "../css/post.module.css"
import Layout from "../components/layout";
import clsx from 'clsx'
import SocialLinks from '../components/social-links'
import RichText from 'src/components/rich-text'
import PublishDetails from 'src/components/publish-details'
import PostCollection from 'src/components/post-collection'

export const query = graphql`
  query ArticleQuery($slug: String!, $postType: String) {
    strapiArticle(slug: { eq: $slug }) {
      title
      subtitle
      excerpt
      content {
        data {
          content
        }
      }
      publishedAt
      postType {
        slug
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG 
              width: 880
              height: 587
              quality: 100
              transformOptions: {cropFocus: CENTER}
            )
          }
        }
      }
      author {
        name
      }
      related {
        title
        slug
        publishedAt
        author {
          name
        }
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 268
                height: 154
                transformOptions: {cropFocus: CENTER}
                placeholder: TRACED_SVG
              ) 
            }
          }
        }
      }
    }
    suggestedArticles: allStrapiArticle(
      filter: {postType: {id: {eq: $postType}}, slug: {ne: $slug}}
      sort: {fields: publishedAt, order: DESC}
      limit: 4
    ) {
      nodes {
        ...Postcard
      }
    }
  }
`;

const Post = ({ data, location, pageContext}) => {
  const article = data.strapiArticle;
  const seo = {
    metaTitle: article.title,
    metaDescription: article.excerpt,
    shareImage: article.image,
    article: true,
  };
  const {country} = pageContext || {}

  return (
    <Layout seo={seo} country={country}>
      <div className={clsx(styles.post, pageGutters)}>
        <div className={styles.header}>
          <GatsbyImage
            className={styles.featureImage}
            alt={`Picture for ${article.title}`}
            image={article.image?.localFile.childImageSharp?.gatsbyImageData}
            loading="eager"
          />
          <div className={styles.headerContent}>
            <h1>{article.title}</h1>
            <PublishDetails author={article.author} publishedAt={article.published_at} />
            {article.subtitle && <h2>{article.subtitle}</h2>}
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.socialMedia}>
            <div className={styles.socialLinks}>
              <SocialLinks title={article.title} path={location.href} />
            </div>
          </div>
          <div className={styles.content}>
            <RichText html={article.content.data.content} />
          </div>
          <div className={styles.relatedContent}>
            {article.related.length > 0 && (
              <div className={styles.relatedContentInner}>
                <h2>Related Content</h2>
                <PostCollection posts={article.related} country={country} column titleComponent="h3"/>
              </div>
            )}
          </div>
        </div>
        <hr />
        <PostCollection posts={data.suggestedArticles.nodes}
                        className={clsx(article.postType.slug === 'case-studies' && styles.caseStudiesPosts)}
                        layout={article.postType.slug === 'case-studies' ? 'cover' : 'default'}
                        country={country} />
      </div>
    </Layout>
  );
};

export default Post;
