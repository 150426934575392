import React from 'react'
import * as styles from '../css/footer.module.css'
import {pageGutters} from '../css/layout.module.css'
import clsx from 'clsx'
import { useStaticQuery, graphql } from "gatsby";
import youdoLogo from '../images/youdo.png'
import Link from './link'

const Footer = ({country}) => {
  const {strapiGlobal: {footerNav, contact: {phone, email, socialMedia}, siteName}} = useStaticQuery(query)

  return (
    <footer className={clsx(styles.footer, pageGutters)}>
      <img src={youdoLogo} alt={siteName} className={styles.logo} />
      <nav className={styles.nav}>
        <ul className={styles.footerNav}>
          {footerNav.filter(({to}) => new URL(to, 'https://blog.youdo.io').pathname.startsWith(`/${country}`)).map(({name, to}) => (
            <li key={name}>
              <Link to={to}>{name}</Link>
            </li>
          ))}
        </ul>
        <ul className={styles.contact}>
          <li>
            Phone: <a href={`tel:${phone.replace(' ', '')}`}>{phone}</a>
          </li>
          <li>
            Email: <a href={`mailto:${email}`}>{email}</a>
          </li>
          {socialMedia.map(({platform, url}) => (
            <li key={platform}>
              <a href={url}>{platform}</a>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  )
}

export default Footer

const query = graphql`
  query {
    strapiGlobal {
      siteName
      footerNav {
        name
        to
      }
      contact {
        email
        phone
        socialMedia {
          platform
          url
        }
      }
    }
  }
`;