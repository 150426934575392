import React from 'react'
import {Link as GatsbyLink} from 'gatsby'

const Link = (props) => {
  const {to, ...rest} = props
  return (
    to.includes('://') ? (
      <a href={to} {...rest} />
    ) : (
      <GatsbyLink {...props} />
    )
  )
}

export default Link
