import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Nav from "./nav";
import Seo from "./seo";
import Footer from "./footer"
import * as styles from 'src/css/layout.module.css'

const Layout = ({ children, seo, country = 'au' }) => (
  <div className={styles.wrapper}>
    <Helmet
      link={[
        {
          rel: "preconnect",
          href: "https://fonts.gstatic.com"
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Rubik&display=swap"
        }
      ]}
    />
    <Seo seo={seo} />
    <Nav country={country} />
    <main className={styles.main}>{children}</main>
    <Footer country={country} />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
