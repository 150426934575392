import React from 'react'
import clsx from 'clsx'
import * as styles from 'src/css/hamburger.module.css'

const Hamburger = ({ open }) => {
  return (
    <div className={clsx(styles.hamburger, open && styles.open)}>
      <span />
    </div>
  )
}

export default Hamburger
