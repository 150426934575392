const preventDefault = (e) => {
  if (e.cancelable !== false) {
    e.preventDefault();
  }
}

export const disableScroll = () => {
  document.body.classList.add('no-scroll')
  window.addEventListener('touchmove', preventDefault, { passive: false });
}
export const enableScroll = () => {
  document.body.classList.remove('no-scroll')
  window.removeEventListener('touchmove', preventDefault);
}