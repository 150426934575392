import React, {useState, useEffect} from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from './link'
import * as styles from "src/css/nav.module.css"
import {pageGutters} from "src/css/layout.module.css"
import youdoLogo from 'src/images/youdo.png'
import IconButton from 'src/components/icon-button'
import clsx from 'clsx'
import Hamburger from 'src/components/hamburger'
import {enableScroll, disableScroll} from 'src/utils/scrolling'

const Nav = ({country}) => {
  const {strapiGlobal: {siteName, mainNav}} = useStaticQuery(query)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (menuOpen) {
      disableScroll()
    } else {
      enableScroll()
    }
    return () => enableScroll()
  }, [menuOpen])

  return (
    <nav className={clsx(styles.nav, pageGutters, menuOpen && styles.open)}>
      <div className={styles.brand}>
        <Link to={`https://youdo.io/${country}/home`}>
          <img src={youdoLogo} alt={siteName} className={styles.logo}/>
        </Link>
      </div>
      <ul className={styles.mainNav}>
        {mainNav.filter(({to}) => new URL(to, 'https://blog.youdo.io').pathname.startsWith(`/${country}`)).map(({name, to}) => (
          <li key={name}>
            <Link to={to}>{name}</Link>
          </li>
        ))}
      </ul>
      <IconButton className={styles.mobileNav} onClick={() => setMenuOpen(open => !open)}>
        <Hamburger open={menuOpen}/>
      </IconButton>
    </nav>
  )
}

export default Nav

const query = graphql`
  query {
    strapiGlobal {
      siteName
      mainNav {
        name
        to
      }
    }
  }
`
